@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,900");
$font-family: "Poppins";
$main-color-bg: #ff9711;
$main-color-bd: #ff9711;
$main-color-font: #ff9711;

body {
  font-family: $font-family;
  font-weight: normal;
  font-size: 12px;
  background-color: #fff;
  color: #1a1a1a;

  margin: 0;
  padding: 0;
  position: relative;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

a {
  color: #181818;
  text-decoration: none;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a i {
  padding: 0 2px;
}

img {
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cursor-pointer {
  cursor: pointer;
}

/*input and button type focus outline disable*/
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
select:focus {
  outline: none;
  box-shadow: none;
}

// .login_main_box{
//   background-image: url("../images/slider-bg.jpg");
//   background-size: contain;
//   background-repeat: no-repeat;

// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var($main-color-font);
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #fff;
}
.login_main_box {
  .login,
  .image {
    min-height: 100vh;
  }

  .bg-imagae {
    background-color: #fffbf600;
    margin: 0;
    padding: 0;
  }
  .bg-light {
    background-color: #fffbf600 !important;
  }

  .logi-font-box {
    font-size: 33px;
    text-transform: capitalize;
    font-weight: 700;
    color: $main-color-font;
  }
  .logi-font-bod {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 800;
    margin-bottom: 18px;
    color: $main-color-font;
    position: relative;
    &::after {
      content: "";
      width: 41px;
      height: 2px;
      top: 25px;
      left: 0px;
      background: $main-color-bd;
      border-radius: 50px;
      position: absolute;
      display: inline-block;
    }
  }
  .logo-box {
    display: block;
    width: 100%;
    position: relative;
    .logo-image {
      object-fit: cover;
      position: absolute;
      width: 173px;
      top: 9px;
      left: 11px;
      z-index: 1;
    }
  }
  .logi-font-box-a {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 600;
    color: #1a1a1a;
  }

  .ling-remembe-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .butt-nox {
    background-color: $main-color-bd;
    border: 1px solid $main-color-bd;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    border-radius: 1px;
    width: 32%;
  }

  .butt-nox:hover {
    background-color: $main-color-bd;
    border: 1px solid $main-color-bd;
    color: #fff;
  }
  .eye-password {
    cursor: pointer;
    position: absolute;
    right: 11px;
    top: 15px;
    color: #000000;
    font-size: 18px;
  }

  .bg-image {
    width: 90%;
    margin: 0 auto;
    object-fit: contain;
  }

  .logo-imge {
    width: 35%;
    object-fit: contain;
    display: block;
  }

  .form-control {
    background-color: #ffffff;
    border: 1px solid #c1bbbb;
  }

  .form-control:focus {
    background-color: #ffff;
    color: $main-color-font;
    font-weight: 700;
    border: 1px solid $main-color-bd;
  }

  .form-floating {
    position: relative;
    input {
      display: block;
      outline: none;
      font-size: 16px;
      color: #202020;
      font-weight: 700;
      border-radius: 0;
      width: 100%;
      height: calc(3.5rem + 2px);
      line-height: 1.25;

      transition: background-color 0.25s ease;
    }
    label {
      color: #474d61;
      font-size: 16px;
      pointer-events: none;
      transition: all 0.25s ease;
    }
  }

  .link-box {
    color: $main-color-font;
    font-size: 15px;
    font-weight: 700;
  }

  .text-danger1 {
    position: absolute;
    top: 27px;
    font-size: 11px;
    right: 2px;
  }

  .account__switch {
    text-align: center;
    .line_font {
      text-decoration: underline;
      font-size: 12px;
      font-weight: 600;
      color: #ff9711;
    }
  }
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  border-radius: 100px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 100px;
  background-clip: padding-box;
  border: 1px solid transparent;
  min-height: 10px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

::-webkit-scrollbar-thumb:hover {
  background: #929292;
} /*# sourceMappingURL=custome.css.map */

.text-danger {
  color: #dc3545 !important;
  position: absolute;
  bottom: -17px;
  right: 0;
  font-size: 12px !important;
}
.mobile_logo {
  display: none;
}
@media screen and (max-width: 1180px) {
  .login_main_box .bg-light {
    width: 100%;
  }
  .login_main_box .bg-imagae {
    width: 100%;
  }
  .login_main_box .d-lg-flex {
    display: none !important;
  }
  .mobile_logo {
    display: block;
    display: block;
    margin: 8px auto 21px;
    width: 160px;
  }
}
