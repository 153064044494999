@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,900");
$font-family: "Poppins";
$main-color-bg: #ff9711;
$main-color-bd: #ff9711;
$main-color-font: #ff9711;
$blue-maine-color: rgb(133, 73, 33);
$white-color: #fff;
$gray-color: #f5f5f5;
$black-color: #773910;
$black1-color: #999;

body {
  font-family: $font-family;
  font-weight: normal;
  font-size: 12px;
  background-color: #f4f6fa;
  overflow: hidden auto !important;
  color: #1a1a1a;
  background-image: url("/assets/images/new-project.png");
  background-size: contain;

  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

a {
  color: #181818;
  text-decoration: none;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a i {
  padding: 0 2px;
}

img {
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cursor-pointer {
  cursor: pointer;
}
.modal-header h6,
h5 {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
tr th {
  font-weight: bold;
  white-space: nowrap;
  font-size: 15px;
  padding: 7px 10px;
  color: $black-color;
  text-transform: capitalize;
  border: 1px solid $white-color;
  background-color: $white-color;
}

tr td {
  font-weight: normal !important;
  white-space: nowrap;
  font-size: 15px;
  padding: 7px 10px;
  color: $black-color;
  text-transform: capitalize;
  border: 1px solid $white-color;
  position: relative;
}
tbody tr:nth-child(even) {
  background-color: $white-color;
}

tbody tr:nth-of-type(odd) {
  background-color: #f8f8f8;
}

/*input and button type focus outline disable*/
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
select:focus {
  outline: none;
  box-shadow: none;
}

.container {
  position: relative;
  width: 100%;
}

// ====================header=======================
.main-header {
  position: fixed;
  z-index: 99;
  top: 0;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #eeeeee;
  transition: all 0.2s ease;

  .topbar {
    width: 100%;
    height: 66px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 10px;
    .navbar-toggler {
      display: none;
    }
    .logo-box {
      margin: 0 0 0 3px;
      img {
        width: 145px;
      }
    }
    .menu_bar_box {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 27px;
      li {
        a {
          position: relative;
          display: block;
          width: 100%;
          display: flex;
          text-decoration: none;
          color: #212020;
          border-bottom: 2px solid #ffffff00;
          white-space: nowrap;
          font-size: 15px;
          font-weight: 500;
          text-transform: capitalize;
          border-bottom: 3px solid transparent;
          line-height: 59px;

          &:hover,
          &.active {
            color: #ff9711;
            border-bottom: 3px solid #ff9711;
          }
        }
      }
    }
    .user-noti-box {
      display: flex;
      justify-content: center;
      align-items: center;

      .user-detle {
        display: flex;
        justify-content: flex-start;
        margin: 0 6px;
        position: relative;
        .dropdown-menu {
          right: 0;
        }
        .user-box {
          width: 40px;
          height: 40px;
          min-width: 40px;
          line-height: 39px;
          img {
            border-radius: 9px;
          }
        }
        .nav-link {
          padding: 0;
        }
        .dropdown-menu[data-bs-popper] {
          top: 92%;
          left: -120px;
          margin-top: 0.125rem;
          padding: 0;
        }
        .user-tsxt {
          h5 {
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
            color: #292d32;
          }
        }
      }

      .dropdown {
        position: relative;
        display: flex;
        align-items: center;
        h6 {
          font-size: 14px;
          font-weight: 500;
          margin: 0;
          cursor: pointer;
          padding: 0;
        }
        .dropbtn {
          border: none;
          cursor: pointer;
          background-color: #fff;

          img {
            width: 30px;
            height: 30px;
            margin-left: 5px;
            vertical-align: middle;
          }
        }

        .dropdown-content {
          display: none;
          position: absolute;
          right: 0;
          background-color: #fff;
          min-width: 150px;
          box-shadow: -2px 0px 28px 0px rgb(0 0 0 / 5%);
          z-index: 1;
          border-radius: 0px;

          a {
            color: #333;
            padding: 10px 16px;
            font-size: 14px;
            text-decoration: none;
            display: block;
            border-bottom: 1px solid #ccc;

            &:hover {
              background-color: #ff8b00;
              color: #fff;
            }
          }
        }

        &:hover {
          .dropdown-content {
            display: block;
          }
        }
      }
      .login_card {
        color: #f77d0b;
        font-size: 14px;
        font-weight: 600;
        .loin_box_link {
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          border-radius: 5px;
          padding: 7px 14px;
          margin: 1px;
          background-color: #f77d0b;
          &:hover {
            background: #cc6708;
          }
        }
      }
      .now_product {
        padding: 8px 11px;
        background: #fff;
        border: 1px solid #f77d0b;
        color: #f77d0b;
        font-weight: 600;
        font-size: 11px;
        display: inline-block;
        text-align: center;
        border-radius: 5px;
        position: relative;
        z-index: 1;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        &:hover {
          background: #ff922c;
          color: #fff;
          box-shadow: inset 0 0 0 4em #ff922c;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main-header .topbar .navbar-toggler {
    display: block;
    &:focus {
      box-shadow: none;
    }
  }
  .main-header .topbar {
    padding: 0 14px 0 2px;
    justify-content: space-between;
  }
  .main-header .navbar-toggler {
    padding: 0;
    margin: 0;
    img {
      width: 24px;
      margin: 0 2px;
    }
  }
  .main-header .topbar .logo-box img {
    width: 105px;
  }

  .main-header .topbar .user-noti-box .login_card {
    margin: 0;
    .loin_box_link {
      font-size: 12px;
    }
  }

  .main-header .topbar .menu_bar_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    position: absolute;
    background: #fdfeff;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);
    transition: all 0.3s linear;
    width: 100%;
    top: 67px;
    padding: 10px 21px;
    li a {
      font-size: 15px;
      line-height: 28px;
    }
    &.collapse:not(.show) {
      display: none;
    }
  }
}
.main_content {
  margin: 64px 0 30px;
  width: 100%;
  position: relative;
  display: block;

  .dummy_box_slider {
    width: 100%;
    display: block;
    overflow: hidden;

    img {
      margin: 0 0;
      width: 100%;
      border-radius: 5px;
    }
  }

  .services_content {
    width: 100%;
    margin: 30px 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .heding_box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h2 {
        font-size: 18px;
        font-weight: 600;
        text-transform: capitalize;
        color: #202020;
      }
      img {
        width: 256px;
      }
      p {
        font-size: 12px;
        font-weight: 500;
        margin: 9px 0 0;
        padding: 0px;
        text-align: center;
        color: #202020;
      }
    }
    .as-service-box {
      background-color: #fff;
      padding: 27px 22px 21px;
      box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 6%);
      position: relative;
      min-height: 295px;
      border-radius: 10px;
      z-index: 1;
      transition: all 0.3s linear;
      overflow: hidden;

      img {
        width: 55px;
      }
      .as-subheading {
        margin: 40px 0 10px;
        font-size: 20px;
        position: relative;
        font-weight: 600;
        transition: none;
        color: #202020;
      }
      p {
        font-size: 12px;
        color: #202020;
      }
      .al-link {
        text-transform: uppercase;
        font-size: 16px;
        color: #f77d0b;
        letter-spacing: 0.6px;
        padding: 0 0 3px;
        position: relative;
        display: inline-block;
      }

      &:hover {
        background-color: #f77d0b;
        &:after {
          visibility: visible;
          opacity: 1;
        }
        img {
          mix-blend-mode: difference;
        }

        .al-link,
        .as-subheading,
        p {
          color: #fff;
        }
      }

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -135px 0 0 -135px;
        background-image: url("/assets/images/service-circle.png");
        width: 271px;
        height: 270px;
        animation: spin 9s infinite linear;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
      }
      @keyframes spin {
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  .kundali_content {
    width: 100%;
    margin: 68px 0 68px;
    overflow: hidden;
    .slick-slide {
      margin: 0 4px;
    }
    .slick-track {
      height: 67px;
    }
    .kudalli_box {
      width: 7.5rem;
      height: 7.5rem;
      background-color: #fff;
      box-shadow: -2px 0px 28px 0px rgb(0 0 0 / 5%);
      border: 1px solid #efefef;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 10px;
      margin: 0px 0px;
      border-radius: 10px;
      &:hover {
        background-color: #ed8623;
        .icon_image img {
          transform: rotate(360deg);
        }
        p {
          color: #ffff;
        }
      }
      .icon_image {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 50px;
        box-shadow: -2px 0px 28px 0px rgb(0 0 0 / 5%);
        border: 1px solid #efefef;
        background-color: #f3f3f3;
        img {
          width: 40px;
          object-fit: cover;
          transition: transform 0.5s ease-in-out;
        }
      }

      p {
        font-size: 13px;
        font-weight: 500;
        margin: 0;
        padding: 0;
        text-transform: capitalize;
        color: #675e56;
      }
    }
  }

  .al-breadcrumb-wrapper {
    background: url("/assets/images/page-title.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 36px 0 50px;
    margin: 0;
    position: relative;
    background-position: center bottom;
    color: #ffff;
    z-index: 1;
    .al-breadcrumb-container {
      h1 {
        color: #fff;
        font-size: 25px;
        font-weight: 700;
        margin: 0 0 17px;
        word-break: break-word;
      }
      ul {
        list-style: none;
        margin: 0;
        display: inline-block;
        padding: 7px 30px;
        position: relative;
        font-size: 13px;
        background: #3f3e3e;
        color: #fff;
        border-radius: 100px;
        li {
          color: #fff;
          display: inline-block;
          margin: 0;
          padding: 0;
          font-weight: 500;
          position: relative;
          word-break: break-word;
          text-transform: capitalize;
          a {
            font-size: 13px;
            color: #f77d0b;
          }
          &:not(:first-of-type):before {
            margin: 0 8px;
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            content: ">";
            font-size: 12px;
            color: inherit;
            line-height: normal;
            font-weight: 900;
          }
        }
      }
    }
  }

  .vedic_content {
    width: 100%;
    margin: 38px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .heding_box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h2 {
        font-size: 18px;
        font-weight: 600;
        text-transform: capitalize;
        color: #202020;
      }
      img {
        width: 256px;
      }
      p {
        font-size: 12px;
        font-weight: 500;
        margin: 9px 0 0;
        padding: 0px;
        text-align: center;
        color: #202020;
      }
    }

    .vediv_user {
      border-radius: 10px;
      overflow: hidden;
      // width: 320px;
      background: white;
      padding: 19px 0px 0px;
      position: relative;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      .serch_box {
        display: flex;
        background-color: #fff;
        padding: 17px 14px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        position: relative;
        input {
          font-family: inherit;
          border: 0;
          background-color: #f3f3f3;
          border-radius: 50px;
          padding: 13px 9px 13px 37px;
          width: 100%;
          :focus {
            outline: none;
          }
        }
        .icon {
          padding: 10px 0 10px 10px;
          position: absolute;
          left: 15px;
        }
      }

      .user-list {
        background-color: #fff;
        list-style-type: none;
        margin: 0;
        height: 300px;
        overflow-y: auto;
        padding: 0;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 7px;
          width: 100%;

          &:not(:last-of-type) {
            border-bottom: 1px solid #eee;
          }

          &.hide {
            display: none;
          }

          .user_card {
            display: flex;
            align-items: center;
            gap: 10px;
            width: calc(100% - 165px);
            img {
              border-radius: 50%;
              object-fit: contain;
              height: 40px;
              width: 40px;
            }

            .user-info {
              display: flex;
              flex-direction: column;
              width: 100%;

              h4 {
                margin: 0;
                font-size: 15px;
                font-weight: 600;
                color: #202020;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              p {
                font-size: 12px;
                color: #202020;
                margin: 0;
              }
            }
          }
          .icon_box {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 5px;
            margin: 0;
            width: 165px;
            img {
              width: 36px;
              height: 36px;
              background: #f1e8e0;
              padding: 6px;
              border-radius: 7px;
              box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            }
            .but_box {
              padding: 8px 12px;
              background: #fff;
              border: 1px solid #f77d0b;
              color: #f77d0b;
              font-weight: 600;
              font-size: 12px;
              display: inline-block;
              text-align: center;
              border-radius: 5px;
              position: relative;
              z-index: 1;
              text-transform: uppercase;
              transition: all 0.3s ease-in-out;
              &:hover {
                background: #f77d0b;
                border: 1px solid #f77d0b;
                color: #fff;
                box-shadow: inset 0 0 0 4em #ff922c;
                transition: all 0.3s ease-in-out;
              }
            }
          }
        }
      }
      .carete_new_butt {
        display: flex;
        background-color: #f77d0b;
        padding: 12px 6px;
        color: #fff;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background: #f58924;
          color: #fff;
          box-shadow: inset 0 0 0 4em #ff922c;
          transition: all 0.3s ease-in-out;
        }
        p {
          font-size: 15px;
          font-weight: 600;
          color: #fff;
          text-align: center;
          padding: 0;
          margin: 0;
        }
      }
    }

    .maching_ditale {
      border-radius: 10px;
      overflow: hidden;
      background: white;
      padding: 19px 0px 0px;
      position: relative;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      .carete_new_butt {
        display: flex;
        background-color: #f77d0b;
        padding: 12px 6px;
        color: #fff;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background: #f58924;
          color: #fff;
          box-shadow: inset 0 0 0 4em #ff922c;
          transition: all 0.3s ease-in-out;
        }
        p {
          font-size: 15px;
          font-weight: 600;
          color: #fff;
          text-align: center;
          padding: 0;
          margin: 0;
        }
      }

      .mach_box {
        background-color: #fdfeff;
        padding: 10px 10px;
        margin: 5px 8px;
        border-radius: 10px;
        border: 1px solid #ca9869;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 7px;
        h2 {
          margin: 0;
          padding: 0;
          font-size: 13px;
          font-weight: 500;
          color: #202020;
          text-transform: capitalize;
          img {
            padding: 5px 0 0;
            object-fit: contain;
            height: 33px;
            width: 33px;
          }
        }
        .section_ser {
          width: 100%;

          select {
            height: 35px;
            background: #f7f5f4;
            border: 1px solid #b5b5b5;
            padding: 0 9px;
            font-size: 15px;
            transition: all 0.3s ease-in-out;
            -webkit-appearance: auto;
            -moz-appearance: auto;
            appearance: auto;
            text-transform: capitalize;
          }
        }
        .form-control {
          height: 35px;
          background: #f7f5f4;
          border: 1px solid #b5b5b5;
          padding: 0 9px;
          font-size: 15px;
          transition: all 0.3s ease-in-out;
          -webkit-appearance: auto;
          -moz-appearance: auto;
          appearance: auto;
          text-transform: capitalize;
        }
        .birt_box {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 5px;
        }
        .add_red {
          width: 100%;
        }
      }
    }

    .panchang_user {
      border-radius: 10px;
      overflow: hidden;
      background: white;
      padding: 19px 0px 0px;
      position: relative;
      height: 522px;
      overflow-y: auto;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      .date-list {
        background-color: #fff;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;

          &:not(:last-of-type) {
            border-bottom: 1px solid #e4e2e2;
          }

          &.hide {
            display: none;
          }
          &.karan_box {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            p {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 50%;
              gap: 5px;
              &:not(:last-of-type) {
                border-right: 1px solid #e4e2e2;
              }
              span {
                margin: 0;
                padding: 0;
                border: none;
                font-weight: 500;
                color: #6e6e6e;
              }
            }
          }
          .date_text {
            font-size: 25px;
          }
          p {
            font-size: 15px;
            font-weight: 600;
            color: #202020;
            margin: 0;
            padding: 0;
            span {
              border: 1px solid #b6b6b6;
              padding: 5px 7px;
              margin: 5px;
              font-weight: 500;
              font-size: 13px;
              border-radius: 5px;
            }
          }
          .user_card {
            display: flex;
            align-items: center;
            img {
              border-radius: 50%;
              object-fit: contain;
              height: 40px;
              width: 40px;
            }

            .user-info {
              margin-left: 15px;

              h4 {
                margin: 0;
                font-size: 15px;
                font-weight: 600;
                color: #202020;
              }

              p {
                font-size: 12px;
                color: #202020;
                margin: 0;
              }
            }
          }
          .icon_box {
            display: flex;
            align-items: center;
            gap: 8px;
            img {
              width: 36px;
              height: 36px;
              background: #f1e8e0;
              padding: 6px;
              border-radius: 7px;
              box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            }
          }
        }
      }
      .carete_new_butt {
        display: flex;
        background-color: #f77d0b;
        padding: 12px 6px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background: #f58924;
          color: #fff;
          box-shadow: inset 0 0 0 4em #ff922c;
          transition: all 0.3s ease-in-out;
        }
        p {
          font-size: 15px;
          font-weight: 600;
          color: #fff;
          text-align: center;
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .Kundli_content {
    width: 100%;
    margin: 30px 0px 90px;

    .heding_card {
      background: #f4f0ec;

      padding: 15px 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        font-size: 15px;
        font-weight: 600;
        color: #f77d0b;
        margin: 0;
        padding: 0;
        width: 50%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .box_button {
        display: flex;
        justify-content: center;
        gap: 8px;
        .pofil_butt {
          border: 1px solid #b6b6b6;
          color: #202020;
          padding: 7px 8px;
          font-weight: 500;
          font-size: 13px;
          border-radius: 5px;
          margin: 0;
          &:hover {
            background-color: #f77d0b;
            border: 1px solid #f77d0b;
            color: #fff;
          }
        }
      }
    }

    .kundli_teb_box {
      display: flex;
      width: 100%;
      align-items: center;
      margin: 15px 0 0;
      background: #fff;
      padding: 6px 9px;
      border-radius: 5px;
      .asto_tab {
        display: flex;
        align-items: center;
        flex-direction: row;
        overflow: auto;
        white-space: pre;
        gap: 21px;
        .link_teb {
          color: #202020;
          border-bottom: 2px solid transparent;
          padding: 4px 0;
          font-weight: 600;
          font-size: 13px;
          text-transform: uppercase;
          line-height: 33px;
          margin: 0;
          transition: all 0.3s ease-in-out;
          &:hover,
          &.active {
            color: #f77d0b;
            border-bottom: 2px solid #f77d0b;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
    .heding_box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 20px 0 0;
      h2 {
        font-size: 18px;
        font-weight: 600;
        text-transform: capitalize;
        color: #202020;
      }
      img {
        width: 256px;
      }
      p {
        font-size: 12px;
        font-weight: 500;
        margin: 9px 0 0;
        padding: 0px;
        text-align: center;
        color: #202020;
      }
    }
    .kundli_ditale_card {
      width: 100%;
      height: auto;
      padding: 0 0;
      margin: 15px 0 0;
      overflow: auto;

      .kundli_box {
        display: block;
        margin: 0 auto;
        height: 100%;
      }
      .next-previous {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        margin: 20px 0 0;

        .next_page {
          border: 1px solid #b6b6b6;
          color: #202020;
          background-color: #fff;
          padding: 7px 8px;
          font-weight: 500;
          font-size: 13px;
          border-radius: 5px;
          margin: 0;
          &:hover {
            background-color: #f77d0b;
            border: 1px solid #f77d0b;
            color: #fff;
          }
        }
      }
    }
  }
  .box_not_text {
    width: 100%;
    height: 100%;
    background-color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 17px;
    padding: 10px;
    h4 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 600;
      text-transform: capitalize;
      height: 100%;
    }
  }
  .box_loading {
    width: 100%;
    background-color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 17px;
    padding: 10px;
  }
  //  .Kundli_content--------------------------------------->

  .kundli_basi {
    width: 100%;
    background-color: #fff;
    padding: 10px 12px 10px;

    .heding_bx {
      font-size: 18px;
      font-weight: 600;
      text-transform: capitalize;
      color: #202020;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 4px 0 0px;
      padding: 9px 0 24px;
      line-height: 8px;
      &::after {
        content: "";
        width: 204px;
        height: 16px;
        display: block;
        position: absolute;
        top: 27px;
        right: 0;
        left: 0;
        margin: 0 auto;
        background-image: url("/assets/images/1-2-1.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .reposive_teble {
      width: 100%;
      overflow: auto hidden;
    }
    .form-control {
      height: 45px;
      background: #f4f0ec;
      border-color: transparent;
      padding: 0 15px;
      font-size: 16px;
      transition: all 0.3s ease-in-out;
      -webkit-appearance: auto;
      -moz-appearance: auto;
      appearance: auto;
      text-transform: capitalize;
    }

    p {
      font-size: 13px;
      font-weight: 400;
      line-height: 17px;
      text-align: justify;
      padding: 0 10px;
      margin: 0;
    }
    .chat_box {
      overflow: auto;
      display: block;
    }
    .text-light {
      background-color: #fff !important;
      color: #4e952f !important;
      font-size: 15px;
      font-weight: 600;
      line-height: 17px;
    }
    .kundli_name {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      padding: 15px 9px 10px;
      p {
        font-size: 14px;
        margin: 0;
        padding: 10px 6px;
        display: inline-block;
        border: 1px solid #3333331a;
        line-height: 16px;
        font-weight: 500;
      }
    }
    .box_card,
    ul {
      width: 100%;
      display: contents;
      li {
        font-size: 14px;
        margin: 0 0;
        width: 100%;
        padding: 2px 3px;
        display: inline-block;
        border: 1px solid #3333331a;
        line-height: 18px;
        font-weight: 500;
      }
      p {
        font-size: 14px;
        margin: 0;
        padding: 2px 6px;
        display: inline-block;
        border: 1px solid #3333331a;
        line-height: 38px;
        font-weight: 500;
      }
      h3 {
        font-size: 15px;
        line-height: 18px;
        font-weight: 500;
        padding: 2px 2px;
        display: inline-block;
        color: #fd9d0d;
      }
    }
    .box_gemstone_card {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid rgba(51, 51, 51, 0.1019607843);
      gap: 5px;
      margin: 0;
      padding: 7px 12px 22px;
      h5 {
        color: #fd9d0d;
        font-size: 14px;
        font-weight: 500;
        line-height: 13px;
        padding: 0;
        margin: 0;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-align: justify;
        padding: 0;
        margin: 0;
      }
    }
    .box_card_current {
      border: 1px solid rgba(51, 51, 51, 0.1019607843);
      margin: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 5px;
      padding: 10px 3px;

      h6 {
        font-size: 15px;
        font-weight: 600;
        line-height: 14px;
        padding: 0;
        margin: 0;
      }

      p {
        font-size: 14px;
        margin: 0;
        padding: 0;
        display: inline-block;
      }
    }
  }
}

.contact-footer {
  background: #231e17;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 23px 3px 9px;
  margin: 0;

  .contact-content {
    text-align: center;
    width: 100%;
    .logo_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      .img-responsive {
        width: 189px;
        height: auto;
      }
      img {
        width: 256px;
        object-fit: cover;
      }
    }
    p {
      font-size: 13px;
      color: #e6ddcb;
      margin: 9px 0 20px;
      padding: 1px 0 17px;
      position: relative;
      border-bottom: 1px solid #504949;
      text-transform: capitalize;
      line-height: 18px;
    }

    h6 {
      color: #e6ddcb;
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 10px;
    }

    span {
      color: #e6ddcb;
      margin: 0 10px;
    }

    .app_ui_box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 12px;
      margin: 21px 0 0;
      .download-btn {
        display: inline-block;
        vertical-align: middle;
        background: #fff;
        border: 2px solid #786e63;
        box-shadow: 1px 4px 17px #3a2b1f87;
        position: relative;
        padding: 8px 3px;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          width: 35px;
          height: 35px;
          display: inline-block;
          margin: 0px 0px 0 11px;
        }
        .inner {
          position: relative;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: flex-end;
          color: #000;
          transition: all 0.4s ease;

          .avail {
            color: #000;
            font-weight: 500;
            line-height: 1em;
            font-size: 11px;
            margin: 0;
          }

          .store-name {
            color: #000;
            text-transform: uppercase;
            font-size: 14px;
            margin: 1px 0 0;
          }
        }
      }
    }
  }

  .copy_pash {
    padding: 30px 0 0;
    color: #e6ddcb;
    font-size: 13px;
    text-align: center;
    span {
      color: #ff9c07;
      font-size: 14px;
      font-weight: 600;
    }
    .link_text {
      color: #ff9c07;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

// model
.form-wrap {
  width: 100%;
  padding: 0;
  margin: 0 auto;
  position: relative;

  .form-group {
    margin-bottom: 15px;
    position: relative;
    > label {
      font-size: 14px;
      color: #202020;
      font-weight: 600;
      margin: 0;
      padding: 1px 7px 4px;
    }
    .icon_box {
      position: absolute;
      top: 39px;
      z-index: 2;
      font-size: 20px;
      right: 0px;
      width: 9%;
    }
    // date box start
    .react-datepicker {
      background-color: #ffff;
    }
    .react-datepicker__header {
      background-color: #ffffff;
      div {
        margin: 0;
      }
      button {
        border: none;
        background: #e3e3e3;
        font-size: 15px;
        font-weight: 600;
        height: 32px;
        color: #f77d4a;
      }
      select {
        width: 100%;
        border: 1px solid #e4e4e4;
        height: 32px;
      }
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      border-radius: 0.3rem;
      background-color: #f77d4a;
      color: #fff;
    }

    // date box end
    .form-control {
      height: 45px;
      background: #f4f0ec;
      border-color: transparent;
      padding: 0 15px;
      font-size: 16px;
      transition: all 0.3s ease-in-out;
      appearance: auto;
      margin: 0 0 8px;

      &:focus {
        border-color: #f77d0b;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      }
    }
    .icon_pass {
      position: absolute;
      top: 38px;
      right: 10px;
    }
  }

  .save_butt {
    padding: 8px 15px;
    background: #f77d0b;
    border: 1px solid #f77d0b;
    color: #fff;
    font-weight: 600;
    font-size: 13px;
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: #fff;
      border: 1px solid #f77d0b;
      color: #fff;
      box-shadow: inset 0 0 0 4em #ff922c;
      transition: all 0.3s ease-in-out;
    }
  }
  .modle_setting {
    width: 100%;
    margin: 0 0 20px;
    padding: 0;
    h3 {
      font-size: 15px;
      font-weight: 600;
      color: #f77d0b;
      margin: 0 0 9px;
      padding: 0 0 17px;
      line-height: 0;
      border-bottom: 1px solid #e1e1e1;
    }
    .set_box {
      display: flex;
      justify-content: flex-start;
      gap: 5px;
      flex-wrap: wrap;
      li {
        margin: 8px 3px;
      }
      .link_box {
        border: 1px solid #b6b6b6;
        color: #202020;
        padding: 7px 8px;
        font-weight: 500;
        font-size: 13px;
        border-radius: 5px;
        text-transform: uppercase;
        margin: 0;
        &:hover {
          background-color: #f77d0b;
          border: 1px solid #f77d0b;
          color: #fff;
        }
      }
    }
  }
}
.malti_card {
  .text-danger {
    position: inherit !important;
  }
}
@media screen and (max-width: 968px) {
  .main_content .Kundli_content .heding_card {
    align-items: center;
    flex-direction: column;
    gap: 14px;
    .box_button {
      flex-wrap: wrap;
    }
    .box_button .pofil_butt {
      padding: 5px 5px;
      font-weight: 500;
      font-size: 12px;
      border-radius: 5px;
      margin: 0;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}

.iconbox_edit {
  display: none;
}
@media screen and (max-width: 768px) {
  .main_content .kundali_content {
    margin: 41px 0 41px;
  }
  .main_content .abou_content {
    margin: 23px 0 23px;
  }
  .main_content .services_content .as-service-box {
    background-color: #fff;
    padding: 22px 14px 15px;
  }
  .main_content .vedic_content .panchang_user .date-list li p {
    font-size: 11px;
    white-space: nowrap;
  }
  .main_content .Kundli_content .heding_card h2 {
    width: 100%;
  }
  .main_content .vedic_content {
    margin: 7px 0 20px;
  }
  .main_content .al-breadcrumb-wrapper {
    padding: 13px 0 15px;
    .al-breadcrumb-container h1 {
      font-size: 15px;
      margin: 0 0 5px;
    }
    .al-breadcrumb-container ul {
      padding: 2px 23px;
      font-size: 10px;
    }
  }

  .main_content .vedic_content {
    .vediv_user {
      padding: 6px 0px 0px;
    }
    .heding_box {
      h2 {
        font-size: 15px;
      }
      img {
        width: 188px;
      }
    }
    .vediv_user .user-list li .icon_box .desktop_ui {
      display: none;
    }
    .vediv_user .user-list li {
      .user_card {
        width: calc(100% - 70px);
        .iconbox_edit {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 5px;
          img {
            width: 22px;
            height: auto;
          }
        }
        .main_content .vedic_content .vediv_user .user-list li .user_card img {
          width: 29px;
          height: 29px;
        }
      }
      .icon_box {
        width: 70px;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: transparent;
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  background-clip: padding-box;
  border: 1px solid transparent;
  min-height: 10px;
  transition: all 0.3s linear;
}

::-webkit-scrollbar-thumb:hover {
  background: #929292;
}

.mt-12 {
  margin-top: 12rem;
}

.dropdaune_box {
  background: white;
  height: auto;
  max-height: 8.9rem;
  display: inline-block;
  overflow: hidden auto;
  width: 100%;
  position: absolute;
  z-index: 5;
  li {
    padding: 7px 9px;
    font-size: 13px;
    line-height: 15px;
    cursor: pointer;
    color: #202020;
    border-bottom: 1px solid #e6e6e6;
    &:hover {
      color: #da6e09;
    }
  }
}

.form-check {
  font-size: 13px;
  color: #202020;
  font-weight: 600;

  .form-check-input:focus[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
  }

  .form-check-input:focus {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }
}

.privacypolicy_box {
  .heding_bx {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    color: #202020;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 4px 0 0px;
    padding: 9px 0 24px;
    line-height: 8px;
    &::after {
      content: "";
      width: 204px;
      height: 16px;
      display: block;
      position: absolute;
      top: 27px;
      right: 0;
      left: 0;
      margin: 0 auto;
      background-image: url("/assets/images/1-2-1.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: justify;
    padding: 0 10px;
    margin: 6px 6px;
    padding: 10px 6px 9px;
    color: #202020;
    .link_text {
      font-size: 14px;
      color: #dd680d;
      font-weight: 500;
    }
  }
}

.kundli_language {
  .language_box {
    display: flex;
    margin: 0 10px 1px 0px;
    li {
      border: none;
      .nav-link {
        border: none;
        font-weight: 600;
        color: #202020;
        &.active {
          color: #dd680d;
          border-bottom: 1px solid #dd680d;
        }
      }
    }
  }
  .btn-success {
    color: #dd680d;
    background-color: transparent;
    border: 1px solid #dd680d;
  }
  .btn-check:active + .btn-success,
  .btn-check:checked + .btn-success,
  .btn-success.active,
  .btn-success:active,
  .show > .btn-success.dropdown-toggle {
    color: #dd680d;
    background-color: transparent;
    border: 2px solid #dd680d;
  }
  .kundli_basi_p {
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    text-align: justify;
    padding: 11px 6px 0;
    margin: 0;
  }
  .heding_bx {
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    color: #202020;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 4px 0 0px;
    padding: 9px 0 24px;
    line-height: 8px;
    &::after {
      content: "";
      width: 204px;
      height: 16px;
      display: block;
      position: absolute;
      top: 27px;
      right: 0;
      left: 0;
      margin: 0 auto;
      background-image: url("/assets/images/1-2-1.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
.modal-spinner-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.modal-spinner-container > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.in_td_box {
  border: 1px solid #e1b39e;
}

@media screen and (max-width: 388px) {
  .main_content .vedic_content .vediv_user .user-list li .user_card .user-info {
    width: 69%;
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .main_content .vedic_content .vediv_user .user-list li .icon_box .but_box {
    padding: 8px 8px;
  }
  .main-header .topbar .user-noti-box .dropdown h6 {
    font-size: 12px;
  }
}
